import { Injectable } from '@angular/core';
import { company as companyData } from '@data/company.data.json';
import { app as appConfig } from '@data/project.data.json';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  app = appConfig;
  home = appConfig.homeLayout;
  title = appConfig.title;
  appType = appConfig.type;
  heading = companyData.heading;
  isStandalone = this.appType === 'standalone' ? true : false;

  constructor() {}
}