import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { HorizontalToolbarComponent } from '@shared/components/horizontal-toolbar/horizontal-toolbar.component';
import { HeaderComponent } from '@shared/components/header/header.component';
import { CompassComponent } from '@shared/components/compass/compass.component';
import { MinimapComponent } from '@shared/components/minimap/minimap.component';
import { SwitchComponent } from '@shared/components/switch/switch.component';
import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';
import { TsLogoComponent } from '@shared/components/ts-logo/ts-logo.component';
import { HotspotComponent } from '@shared/components/hotspot/hotspot.component';
import { SocialLinksComponent } from '@shared/components/social-links/social-links.component';
import { NavComponent } from '@shared/components/nav/nav.component';
import { SafeHtmlPipe } from '@shared/pipes/safe-html.pipe';
import { AbTestComponent } from '@shared/components/ab-test/ab-test.component';
import { DarkModeComponent } from '@shared/components/dark-mode/dark-mode.component';
import { WalkthroughComponent } from '@shared/components/walkthrough/walkthrough.component';
import { EasterEggComponent } from '@shared/components/easter-egg/easter-egg.component';
import { ZoomIndicatorComponent } from '@shared/components/zoom-indicator/zoom-indicator.component';
import { ViewpointMapComponent } from './viewpoint-map/viewpoint-map.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { BackButtonComponent } from './back-button/back-button.component';
import { ContentDialogComponent } from './content-dialog/content-dialog.component';

@NgModule({
  declarations: [
    HorizontalToolbarComponent,
    HeaderComponent,
    CompassComponent,
    MinimapComponent,
    SwitchComponent,
    ModalDialogComponent,
    TsLogoComponent,
    HotspotComponent,
    SocialLinksComponent,
    NavComponent,
    AbTestComponent,
    SafeHtmlPipe,
    DarkModeComponent,
    WalkthroughComponent,
    EasterEggComponent,
    ZoomIndicatorComponent,
    ViewpointMapComponent,
    BackButtonComponent,
    ContentDialogComponent,
  ],
  exports: [
    HorizontalToolbarComponent,
    HeaderComponent,
    CompassComponent,
    MinimapComponent,
    SwitchComponent,
    ModalDialogComponent,
    TsLogoComponent,
    HotspotComponent,
    SocialLinksComponent,
    NavComponent,
    AbTestComponent,
    SafeHtmlPipe,
    DarkModeComponent,
    WalkthroughComponent,
    EasterEggComponent,
    ZoomIndicatorComponent,
    ViewpointMapComponent,
    BackButtonComponent,
    ContentDialogComponent,
  ],
  imports: [
    CommonModule,
    LeafletModule,
    AppRoutingModule,
    IvyCarouselModule,
    ModalModule.forRoot(),
  ],
})
export class SharedModule {}
