import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StateService } from '@shared/services/state.service';
import { Viewpoint } from '@shared/models';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent implements OnInit {
  @Input('viewpoint') viewpoint: Viewpoint;
  @Input('checked') checked: boolean = false;
  @Input('overlayChecked') overlayChecked: boolean = false;
  @Input('vegetationChecked') vegetationChecked: boolean = true;
  @Output() check = new EventEmitter();
  @Output() checkLayer = new EventEmitter();
  toggleState: any;
  state: any;
  standalone: boolean;

  constructor(stateService: StateService, private appService: AppService) {
    this.state = stateService.getState();
  }

  ngOnInit(): void {
    // if (this.state.viewpoints[this.viewpoint.slug].toggleState) {
    //   this.toggleState = this.state.viewpoints[this.viewpoint.slug].toggleState;
    // } else {
    this.standalone = this.appService.isStandalone;
    this.toggleState = {
      overlay: {
        checked: false,
      },
      vegetation: {
        checked: false,
      },
    };
    // }
  }

  toggle() {
    this.checked = !this.checked;
    //emit check event to parent viewpoint controller
    this.check.emit({ checked: this.checked });
    //emit viewpoint for header to receive
  }

  toggleLayer(layerName) {
    this.toggleState[layerName].checked = !this.toggleState[layerName].checked;
    this.checkLayer.emit(this.toggleState);
  }
}
