import { Component, Input, NgZone, OnInit, Output } from '@angular/core';
import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Scene } from 'marzipano';
import { ContentDialogComponent } from '../content-dialog/content-dialog.component';

@Component({
  selector: 'app-hotspot',
  templateUrl: './hotspot.component.html',
  styleUrls: ['./hotspot.component.scss'],
})
export class HotspotComponent implements OnInit {
  @Input('data') hotspot: any;
  @Input('scene') scene: Scene;
  @Input('sceneId') sceneId: string;
  @Output() modalRef: BsModalRef;
  isShowInfo: boolean = false;
  isShowInfoContent: boolean = false;

  constructor(private modalService: BsModalService, private ngZone: NgZone) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.add();
  }

  add() {
    let that = this;
    // WORKAROUND: Need to wait until hotspots are in the DOM
    this.ngZone.runOutsideAngular(() => {
      setTimeout(function () {
        var element: HTMLElement = document.getElementById(
          that.sceneId + '_' + that.hotspot.slug
        );
        var position = { yaw: that.hotspot.yaw, pitch: that.hotspot.pitch };
        var position = { yaw: that.hotspot.yaw, pitch: that.hotspot.pitch };
        element.style.opacity = '1';
        that.scene.hotspotContainer().createHotspot(element, position);
      }, 0);
    });
  }

  openDialog() {
    const initialState = {
      hotspot: this.hotspot,
    };

    const dialogSize = this.hotspot.type == 'image' ? 'modal-xl' : 'modal-sm ';
    this.modalRef = this.modalService.show(ContentDialogComponent, {
      initialState,
      class: `${dialogSize} modal-dialog-centered`,
      keyboard: true,
      ignoreBackdropClick: false,
    });
  }

  toggleInfo() {
    if (this.isShowInfo) {
      this.isShowInfoContent = false;
      this.ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          this.isShowInfo = false;
        }, 500);
      });
    } else {
      this.isShowInfo = true;
      this.ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          this.isShowInfoContent = true;
        }, 500);
      });
    }
  }
}
