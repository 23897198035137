import { Component, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'ts-content-dialog',
  templateUrl: './content-dialog.component.html',
  styleUrls: ['./content-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContentDialogComponent {
  public hotspot: any;

  constructor(public modalRef: BsModalRef) {}

  ngOnInit(): void {}
}
