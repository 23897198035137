<div [id]="sceneId + '_' + hotspot?.slug" style="opacity: 0; width: 50px">
  <div
    *ngIf="hotspot?.args && hotspot?.args.label"
    class="label"
    [ngClass]="isShowInfo ? 'open' : ''"
    [ngStyle]="
      hotspot?.args.width && isShowInfo && { width: hotspot?.args.width + 'px' }
    "
  >
  <h3 [innerHTML]="hotspot.title"></h3>
  </div>
  <div class="hotspot-wrapper">
    <h2
      *ngIf="hotspot?.args && hotspot?.args.featured"
      [innerHTML]="hotspot?.title"
    ></h2>
    <div
    (click)="openDialog()"
      class="hotspot"
      [ngClass]="hotspot?.args.featured ? 'featured' : ''"
      [attr.data-slug]="hotspot?.slug"
    >
    <img src="assets/images/{{hotspot.type}}.svg" alt=""> 
    </div>
  </div>
</div>
